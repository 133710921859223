import React from 'react';
import { Link, graphql } from 'gatsby';
import TextLoop from 'react-text-loop';

import Layout from '../components/layout';
import SEO from '../components/seo';
import { Play } from '../icons';

interface Props {
  data: {
    allMarkdownRemark: {
      edges: {
        node: {
          excerpt: string;
          fields: {
            readingTime: {
              text: string;
            };
            slug: string;
          };
          frontmatter: {
            date: string;
            description: string;
            title: string;
          };
        };
      }[];
    };
    fileName: {
      childImageSharp: {
        fluid: any;
      };
    };
    site: {
      siteMetadata: {
        title: string;
      };
    };
  };
  location: {
    pathname: string;
  };
}

const Index = ({ data, location }: Props) => {
  const siteTitle = data.site.siteMetadata.title;
  const { edges: posts } = data.allMarkdownRemark;

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="Tory Walker" />
      <div className="outer">
        <div className="inner">
          <div style={{ margin: 70, textAlign: 'center' }}>
            <h2 className="site-description">
              Hi! 👋 I'm Tory Walker, a
              <TextLoop>
                <span>&nbsp;developer</span>
                <span>&nbsp;blogger</span>
                <span>&nbsp;programmer</span>
                <span>&nbsp;creator</span>
              </TextLoop>
              {'. '}
            </h2>
          </div>
          <h1>Latest Posts</h1>
          <div className="post-feed">
            {posts.slice(0, 3).map(({ node }) => {
              const title = node.frontmatter.title || node.fields.slug;

              return (
                <article className="post-card post">
                  <Link to={node.fields.slug} className="post-card-image-link">
                    <img
                      className="post-card-image"
                      sizes="(max-width: 1000px) 400px, 700px"
                      src="https://static.ghost.org/v2.0.0/images/creating-a-custom-theme.jpg"
                      alt={title}
                    />
                  </Link>
                  <div className="post-card-content">
                    <Link
                      to={node.fields.slug}
                      className="post-card-content-link"
                    >
                      <header className="post-card-header">
                        <span className="post-card-tags">
                          {node.frontmatter.date}
                        </span>
                        <h2 className="post-card-title">{title}</h2>
                      </header>
                      <section className="post-card-excerpt">
                        <p
                          dangerouslySetInnerHTML={{
                            __html:
                              node.frontmatter.description || node.excerpt,
                          }}
                        />
                      </section>
                    </Link>
                    <footer className="post-card-meta">
                      {/* FIXME: figure out what we want to put here */}
                      <div className="author-list" />
                      <span className="reading-time">
                        {node.fields.readingTime.text}
                      </span>
                    </footer>
                  </div>
                </article>
              );
            })}
          </div>
          {posts.length > 3 && (
            <div
              className="reading-time"
              style={{
                textAlign: 'end',
                margin: '-20px 0 20px 0',
              }}
            >
              <Link to="blog" style={{ color: 'black' }}>
                more →
              </Link>
            </div>
          )}

          <h1>Latest Videos</h1>
          <div className="post-feed">
            {posts.slice(0, 3).map(({ node }) => {
              const title = node.frontmatter.title || node.fields.slug;

              return (
                <article className="post-card post">
                  <Link to={node.fields.slug} className="post-card-image-link">
                    <img
                      className="post-card-image"
                      sizes="(max-width: 1000px) 400px, 700px"
                      src="https://static.ghost.org/v2.0.0/images/welcome-to-ghost.jpg"
                      alt={title}
                    />
                    <Play
                      style={{
                        position: 'absolute',
                        top: '40%',
                        right: '42%',
                        color: 'white',
                        width: 50,
                      }}
                    />
                  </Link>
                  <div className="post-card-content">
                    <Link
                      to={node.fields.slug}
                      className="post-card-content-link"
                    >
                      <header className="post-card-header">
                        {/* FIXME: Make this something real - tags? */}
                        <span className="post-card-tags">
                          {node.frontmatter.date}
                        </span>
                        <h2 className="post-card-title">{title}</h2>
                      </header>
                    </Link>
                    <footer className="post-card-meta">
                      {/* FIXME: figure out what we want to put here */}
                      <div className="author-list" />
                    </footer>
                  </div>
                </article>
              );
            })}
          </div>
          {posts.length > 3 && (
            <div
              className="reading-time"
              style={{
                textAlign: 'end',
                margin: '-20px 0 20px 0',
              }}
            >
              <Link to="videos" style={{ color: 'black' }}>
                more →
              </Link>
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default Index;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            readingTime {
              text
            }
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
          }
        }
      }
    }
    fileName: file(absolutePath: { regex: "/profile-pic.jpg/" }) {
      childImageSharp {
        fluid(maxWidth: 400, maxHeight: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
